<template>
  <div class="modal alarm">
    <div class="modal__wrapper">
      <button class="close modal__close" @click="$emit('on-close')">
        <fa-icon icon="times"></fa-icon>
      </button>
      <div class="modal__title">{{ $t('combiStatus.title') }}</div>
      <p>
        <a href="https://www.billo.be/planningbillo" target="_blank" class="button">{{ $t('combiStatus.planning') }}</a>
      </p>
      <div class="modal__subtitle">{{ $t('combiStatus.todo') }}</div>
      <div class="modal__body">
        <p v-for="(item,index) in alarm.combinaties" :key="`alarmcombi--${index}`">
          {{ item.NAAMBEDRIJF }} => {{ item.AANTAL }}
        </p>
        <div class="alarm__data" v-if="alarm.details.length">
          <table class="table">
            <thead>
            <tr class="table__header">
              <th class="table__header__column" v-for="key in Object.keys(alarm.details[0])"
                  :key="`alarm-header--${key}`">
                {{ key }}
              </th>
            </tr>
            </thead>
            <tbody class="table__body">
            <tr class="table__body__item" v-for="(item,index) in alarm.details"
                :key="`alarm-item--${index}`">
              <td class="table__body__item__column"
                  v-for="key in Object.keys(item)"
                  :key="`alarm-item--${index}-${key}`">
                {{ item[key] }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlarmCombi",
  computed: {
    alarm() {
      return this.$store.getters['dispatch/alarm'];
    }
  }
}
</script>