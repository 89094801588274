<template>
  <div class="user" v-if="userData"><span>{{ userData.naam }}</span></div>
</template>

<script>
export default {
  name: "User",


  computed: {
    userData(){
      return this.$store.getters['user/userData'];
    }
  }
}
</script>