export default{
    setRitten(state, payload){
        state.ritten = payload;
    },

    setNumbers(state,payload){
        state.numbers = payload;
    },

    setPages(state, payload){
        state.pages = payload;
    },

    setBedrijven(state, payload){
        state.bedrijven = payload;
    },

    setCustomers(state, payload){
        state.customers = payload;
    },

    setActiveRit(state, payload){
        state.activeRit = payload;
    },

    setActiveSubReizigers(state,payload){
        state.activeSubReizigers = payload;
    },

    setAlarm(state,payload){
        state.alarm = payload;
    },

    setTasks(state,payload){
        state.tasks = payload;
    },

    setLastChauffeur(state,payload){
        state.lastChauffeur = payload;
    },

    setChauffeurs(state, payload){
        state.chauffeurs = payload;
    },
    setActiveChauffeur(state, payload){
        state.activeChauffeur = payload;
    }
}