import apihelper from "@/helpers/apihelper";
export default{
    async getOrders(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billohelpdesk/getBookings`;
        if(payload.sortField.toUpperCase() === "DATUM"){
            payload.sortOrder = payload.sortOrder === "DESC"?"ASC":"DESC";
        }
        const data = {
            PageNumber: payload.page,
            PageSize: payload.amount,
            ID: payload.query.idsubreiziger || "",
            RITID: payload.query.idrit || "",
            IDEXTRABETALING: payload.query.idbetaling || "",
            BONNR: payload.query.bonnr || "",
            PO: payload.query.po || "",
            DATUM: payload.query.datum || "",
            EMAIL: payload.query.email || "",
            TEL: payload.query.gsm || "",
            NAAM: payload.query.naam || "",
            STRAAT: payload.query.straat || "",
            POSTNR: payload.query.postnr || "",
            STAD: payload.query.stad || "",
            HT: payload.query.ht || "",
            EXTRATEBETALEN: payload.query.extraToPay,
            ORDERBY: payload.sortField || "",
            SORTORDER: payload.sortOrder
        }
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(data),
            errorMsg: 'Failed to fetch billo orders.'
        });

        context.commit('setOrders', responseData.data);
        context.commit('setPages', Math.ceil(responseData.metadata.count / payload.amount));
    },

    clearOrders(context){
        context.commit('setOrders', []);
        context.commit('setPages', 0);
    },

    async getOrderDetail(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billohelpdesk?bonnr=${payload}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch billo order detail.'});
        return responseData;
    },

    async getOrderHistory(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billohelpdesk/leeswijzigingen/${payload}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch billo order history.'});
        return responseData.data;
    },

    async getOrderMailHistory(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billohelpdesk/searchemail?q=${payload}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to get order mail history.'});
        return responseData.data;
    },

    async getOrderPaymentStatus(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/payment/statusbetaling?PSPID=${payload.pspid}&orderid=${payload.orderID}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to get order payment status.'});
        return responseData;
    },

    async calcOrderPrice(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/changebilloprice`;
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to calculate billo order price.'
        });
        return responseData.data;
    },

    async getTalic(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billohelpdesk/findtalic?q=${payload}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch talic.'});
        return responseData.data;
    },

    async getRitLinked(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billohelpdesk/leesgekoppeld?ritid=${payload.ritid}&richting=${payload.richting}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch linked to billo order rit.'});
        return responseData.data;
    },

    async getRitByBonnr(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billohelpdesk/searchbonnr?richting=${payload.richting}&bonnr=${payload.bonnr}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to find rit by bonnr.'});
        return responseData.data;
    },

    async getExtraPayments(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billohelpdesk/leesextrabetalingen?bonnr=${payload}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch extra betalingen.'});
        return responseData;
    },

    async getVouchers(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billohelpdesk/leesvouchers?bonnr=${payload}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch vouchers.'});
        return responseData;
    },

    async saveOrder(context, payload){
        const token = context.rootGetters.token;
        payload.userid = context.rootGetters['user/userData'].id;
        payload.username = context.rootGetters.userName;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billohelpdesk/savebooking`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to update billo order.'
        });
    },

    async createRit(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billohelpdesk/maakrit/${payload}?userid=${context.rootGetters['user/userData'].id}&username=${context.rootGetters.userName}`;
        await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to create billo order rit.'});
    },

    async addRitAddress(context, payload){
        const token = context.rootGetters.token;
        payload.username = context.rootGetters.userName;
        payload.userid = context.rootGetters['user/userData'].id;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billohelpdesk/extraaddress`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to add address to billo order rit.'
        });
    },

    async combineRit(context, payload){
        const token = context.rootGetters.token;
        payload.username = context.rootGetters.userName;
        payload.userid = context.rootGetters['user/userData'].id;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billohelpdesk/voegsamen`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to combine billo order rit.'
        });
    },

    async unlinkRit(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billohelpdesk/ontkoppelrit?ritid=${payload}&userid=${context.rootGetters['user/userData'].id}&username=${context.rootGetters.userName}`;
        await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to unlink billo order rit.'});
    },

    async addExtraPayment(context, payload){
        const token = context.rootGetters.token;
        payload.user_name = context.rootGetters.userName;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/payment/addextrabetaling`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to create extra betaling.'
        });
    },

    async editExtraPayment(context, payload){
        const token = context.rootGetters.token;
        payload.user_name = context.rootGetters.userName;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/payment/editextrabetaling`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to edit extra betaling.'
        });
    },

    async deleteExtraPayment(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/payment/delextrabetaling`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to delete extra betaling.'
        });
    },

    async toggleExtraPayment(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/payment/toggleextrabetaling`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to toggle extra betaling.'
        });
    },

    async addVoucher(context, payload){
        const token = context.rootGetters.token;
        payload.user_name = context.rootGetters.userName;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/payment/addvoucher`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to create voucher.'
        });
    },

    async deleteVoucher(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/payment/delvoucher`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to delete voucher.'
        });
    },

    //mailing
    async sendOrderConfirmation(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/sendmail/${payload.bonnr}?profile=BILLO&template=billoconfirmation&taal=${payload.lang.toUpperCase()}`;
        await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to mail order confirmation.'});
    },

    async sendPickup(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/sendmail/${payload.id}?profile=BILLO&template=billopickup&taal=${payload.lang.toUpperCase()}`;
        await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to mail pickup.'});
    },

    async sendAnnulation(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/sendmail/${payload.id}?profile=BILLO&template=billoannulatie&taal=${payload.lang.toUpperCase()}`;
        await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to mail order annulation.'});
    },

    async sendVoucher(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/payment/sendemailvoucherbillo?code=${payload.code}&email=${payload.email}&taal=${payload.lang}`;
        await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to mail voucher.'});
    },

    async sendExtraPayment(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/payment/sendemailextrabetalingbillo/${payload.id}${payload.receiver?`?receiver=${payload.receiver}`:''}`;
        await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to mail extra payment link.'});
    },

    async sendPaymentReminder(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/payment/sendreminderextrabetalingbillo?bonnr=${payload.bonnr}${payload.receiver?`&receiver=${payload.receiver}`:''}`;
        await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to mail payment reminder.'});
    }
}