import Vue from 'vue'
import VueRouter from 'vue-router'

//pages
import Home from '@/views/Home.vue'
import Login from "@/views/Login";

//user
import {store} from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresUnauth: true
    }
  },

  {
    path: '/reset-password',
    name: 'ResetPassword',

    component: () => import('@/views/ResetPassword')
  },

  {
    path:'/billo-helpdesk',
    name:'BilloHelpdesk',
    component: () => import('@/views/BilloHelpdesk'),
    meta: {
      requiresAuth: true,
      rights:'billo_helpdesk'
    }
  },
  {
    path:'/vip-drive',
    name:'VipDrive',
    component: () => import('@/views/VipDrive'),
    meta: {
      requiresAuth: true,
      rights:'vip_helpdesk'
    }
  },
  {
    path:'/travelagents',
    name:'Travelagents',
    component: () => import('@/views/Travelagents'),
    meta: {
      requiresAuth: true,
      rights:'travelagents'
    }
  },
  {
    path:'/dispatch',
    name:'Dispatch',
    component: () => import('@/views/Dispatch'),
    meta: {
      requiresAuth: true,
      rights:'dispatch'
    }
  },
  {
    path:'/history',
    name:'History',
    component: () => import('@/views/History'),
    meta: {
      requiresAuth: true,
      rights: 'dispatch'
    }
  },
  {
    path:'/combination',
    name:'Combination',
    component: () => import('@/views/Combination'),
    meta: {
      requiresAuth: true,
      rights: 'combinatieritten'
    }
  },
  {
    path:'/planning',
    name:'Planning',
    component: () => import('@/views/PlanningOverview'),
    meta: {
      requiresAuth: true,
      rights: 'planningloodswezen'
    }
  },
  {
    path:'/vouchers',
    name:'Vouchers',
    component: () => import('@/views/Vouchers'),
    meta: {
      requiresAuth: true,
      rights: 'vouchers'
    }
  },
  {
    path:'/customers',
    name:'Customers',
    component: () => import('@/views/Customers'),
    meta: {
      requiresAuth: true,
      rights:'klanten'
    }
  },
  {
    path:'/invoicing',
    name: 'Invoicing',
    component: ()=>import('@/views/Invoicing'),
    meta:{
      requiresAuth: true,
      rights: 'vip_facturatie'
    }
  },
  {
    path:'/intranet-users',
    name:'IntranetUsers',
    component: () => import('@/views/IntranetUsers'),
    meta: {
      requiresAuth: true,
      rights: 'intranet_users'
    }
  },
  {
    path: '/*',
    redirect: '/'
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach(function (to, _, next) {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next({
      path: '/login',
      query: {
        nextUrl: to.fullPath
      }
    });
  } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
    next('/');
  } else {
    if(to.meta.rights) {
      if (to.meta.rights && store.getters['user/userData']['rights'][to.meta.rights]) {
        next();
      } else {
        next('/');
      }
    }
    else{
      next();
    }
  }
});

export default router;
