export default{
    vouchers(state){
        return state.vouchers;
    },

    hasVouchers(state){
        return state.vouchers && state.vouchers.length;
    },

    promos(state){
        return state.promos;
    },

    hasPromos(state){
        return state.promos && state.promos.length;
    },

    pages(state){
        return state.pages;
    }
}