<template>
  <div class="page page--login">
    <div class="login">
      <template v-if="isResetActive">
        <h1 class="title login__title">{{ $t('forgot.reset') }}</h1>
        <template v-if="isResetSuccess">
          <p class="success">{{ $t('forgot.sent') }}</p>
          <p class="login__forgotten" @click="returnLogin">{{ $t('forgot.back') }}</p>
        </template>
        <template v-else>
          <label for="userreset">{{ $t('login.username') }}</label>
          <input class="input login__input input--login" id="userreset" type="text"
                 :name="Math.round(Math.random() * Date.now())"
                 v-model="resetname" :placeholder="$t('login.username')" autocomplete="new-password"/>
          <button class="button button--cancel" type="button" @click="returnLogin">{{ $t('cancel') }}</button>
          <button class="button" type="button"
                  @click="submitReset">{{ $t('forgot.sendRequest') }}
          </button>
          <div class="error login__error"><span v-if="isResetError">{{ $t('forgot.sendError') }}</span>
          </div>
        </template>
      </template>
      <template v-else>
        <h1 class="title login__title">{{ $t('login.title') }}</h1>
        <label for="user">{{ $t('login.username') }}</label>
        <input class="input login__input input--login" id="user" type="text"
               :name="Math.round(Math.random() * Date.now())"
               v-model="username" :placeholder="$t('login.username')" autocomplete="new-password"/>
        <label for="pass">{{ $t('login.password') }}</label>
        <input class="input login__input input--login" id="pass" type="password"
               :name="Math.round(Math.random() * Date.now())"
               v-model="password" @keypress.enter="submitLogin" :placeholder="$t('login.password')"
               autocomplete="password"/>
        <button class="button" :class="{'button--disabled':authenticating||!loginActive}"
                type="button"
                @click="submitLogin">{{ $t('login.submit') }}
        </button>
        <div class="login__forgotten" @click="isResetActive=true">{{ $t('forgot.passwordforgotten') }}?</div>
        <div class="error login__error"><span v-if="loginError">{{ loginError }}</span></div>
      </template>

    </div>
  </div>
</template>

<script>

export default {
  name: "Login",

  data() {
    return {
      username: "",
      password: "",

      errorVisible: false,
      errorMessage: "",

      loginError: null,
      authenticating: false,

      resetname: "",
      isResetActive: false,
      isResetError: false,
      isResetSuccess: false
    };
  },

  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated && this.$store.getters['user/userData'];
    },
    loginActive() {
      return this.username !== '' && this.password !== '';
    }
  },

  watch: {
    isAuthenticated(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        if(this.$route.query.nextUrl){
          this.$router.replace(this.$route.query.nextUrl);
          return;
        }
        this.$router.replace('/');
      }
    }
  },

  methods: {
    async submitLogin() {
      this.loginError = null;
      if (this.username !== '' && this.password !== '') {
        this.authenticating = true;
        const payload = {
          username: this.username,
          password: this.password
        };
        try {
          await this.$store.dispatch('login', payload);
        } catch (e) {
          this.loginError = e;
        }
        this.authenticating = false;
        this.password = "";
      }
    },

    async submitReset() {
      if (this.resetname) {
        try {
          const res = await this.$store.dispatch('user/recoverPassword', this.resetname);
          if (res.status) {
            this.isResetSuccess = true;
            return;
          }
          this.isResetError = true;
        } catch (e) {
          this.isResetError = true;
        }
      }
    },

    returnLogin() {
      this.isResetSuccess = false;
      this.isResetError = false;
      this.isResetActive = false;
      this.resetname = "";
    }
  }
}
</script>