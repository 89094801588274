import apihelper from "@/helpers/apihelper";

export default{
    async getCustomers(context){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/combinatieritten/listcustomers`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch combination customers.'});
        context.commit('setCustomers', responseData.data);
    },

    clearCustomers(context){
        context.commit('setCustomers', []);
    },

    async getRitten(context, {custid,date}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/combinatieritten/listritten?custid=${custid}&date=${date}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch combination ritten for selected customer.'});
        context.commit('setRitten', responseData.data);
    },

    clearRitten(context){
        context.commit('setRitten',[]);
    },

    async getSubreizigers(context, {id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/combinatieritten/listpassagiers/${id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch subreizigers for selected rit.'});
        return responseData.data;
    },

    async getOrders(context, {custid,date}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/combinatieritten/listopenorders?custid=${custid}&date=${date}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch combination open orders for selected customer.'});
        context.commit('setOrders', responseData.data);
    },

    clearOrders(context){
        context.commit('setOrders', []);
    },

    async unlinkRit(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/combinatieritten/ontkoppelrit`;
        payload.user = {
            id: context.rootGetters['user/userData'].id,
            name: context.rootGetters.userName
        }
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to unlink combination.'
        });
    },

    async linkRit(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/combinatieritten/koppelrit`;
        payload.user = {
            id: context.rootGetters['user/userData'].id,
            name: context.rootGetters.userName
        }
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to link order to combination.'
        });
    },

    async createRit(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/combinatieritten/maakrit`;
        payload.user = {
            id: context.rootGetters['user/userData'].id,
            name: context.rootGetters.userName
        }
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to create rit for order.'
        });
        return responseData.data;
    },

    async sortRit(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/combinatieritten/sorteerrit`;
        payload.user = {
            id: context.rootGetters['user/userData'].id,
            name: context.rootGetters.userName
        }
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to sort rit subreizigers.'
        });
    },

    async sendPickup(context, {id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/combinatieritten/emailpickuptimerit/${id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to email rit pickuptime.'});
        return responseData.data;
    },
    async sendPickupCustomer(context, {custid, date}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/combinatieritten/emailpickuptimecustomerdate?custid=${custid}&date=${date}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to email all pickuptimes.'});
        return responseData.data;
    }
}