export default{
    setAirports(state,payload){
        state.airports = payload;
    },
    setCarTypes(state,payload){
        state.carTypes = payload;
    },
    setDelijnnrs(state, payload){
        state.delijnnrs = payload;
    }
}