import apihelper from "@/helpers/apihelper";
export default{
    async getAirports(context){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/airports`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch airports.'});
        context.commit('setAirports', responseData.data);
    },

    async getCarTypes(context){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/leessoortenwagens`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch car types.'});
        context.commit('setCarTypes', responseData.data);
    },

    async getDelijnnrs(context){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/klantnrsdelijn`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch delijnnrs.'});
        context.commit('setDelijnnrs', responseData.data);
    },
    async checkAddress(context, payload){
        const token = context.rootGetters.token;
        payload.postal = payload.postal[0] === 'L' ? payload.postal.substring(1, 5) : payload.postal;
        const country = payload.postal.length === 4 ? "BE" : "";
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/locations/geocode/${payload.street} ${payload.postal} ${payload.city} ${country}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to validate address.'});
        if (!responseData.data || !responseData.data.FORMATTED_ADDRESS) {
            const error = new Error(
                responseData.error_description || 'Failed to validate address.'
            );
            throw error;
        }
    },

    async getCarData(context, {nrplaat}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/locatecar?nrplaat=${nrplaat}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch car tracking data.'});
        return responseData.data;
    },

    async getAllCarsData(context){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/tomtom/statuswagens`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch car map tracking data.'});
        const fullData = responseData.data;
        const carData = fullData.tomtom.map(t => ({...t, ...fullData.chauffeurs.find(c =>c.pers_id == t.driver)}));
        return carData;
    },

    async postIssue(context,payload){
        const data = {
            summary:`${payload.page} - ${payload.title}`,
            description:`${payload.subject} - ${payload.text} - ${context.rootGetters.userName}`
        }
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/jira/createissue`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(data),
            errorMsg: 'Failed to create IT issue.'
        });
    }
}