import apihelper from "@/helpers/apihelper";

export default {
  async getRitten(
    context,
    { PageNumber, PageSize, query, ORDERBY, SORTORDER }
  ) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/leesrittenscherm`;
    if (ORDERBY.toUpperCase() === "DATUM") {
      SORTORDER = "";
    }
    let filter1;
    if (query.filter1) {
      let f1 = query.filter1.split(" ").join("");
      filter1 = f1.split(",");
    }
    const datum = [];
    if (query.datumV) {
      datum.push(query.datumV);
    }
    if (query.datumT) {
      datum.push(query.datumT);
    }
    const payload = {
      PageNumber,
      PageSize,
      table: "DISPATCHRITTEN",
      ORDERBY: `${ORDERBY ? ORDERBY : ""} ${SORTORDER ? SORTORDER : ""}`,
      id: query.id || "",
      bedrijf: query.bedrijf,
      filter1,
      vluchtnr: query.vluchtnr || "",
      linkklant: query.linkklant || "",
      notlinkklant: query.notlinkklant || "",
      buitenland: query.buitenland || "",
      datum,
      find: query.find || "",
      soortwagen: query.soortwagen,
      combicomfort: query.combicomfort,
      nocombi: query.nocombi,
      geenoa: query.geenoa,
    };
    const responseData = await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to fetch dispatch ritten.",
    });
    context.commit("setRitten", responseData.data);
    context.commit("setNumbers", {
      count: responseData.metadata.count,
      aantallen: responseData.metadata.aantallen,
    });
    context.commit(
      "setPages",
      Math.ceil(responseData.metadata.count / responseData.metadata.PageSize)
    );
  },

  async printRitten(context, { query, ORDERBY, SORTORDER }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/printrittenscherm`;
    if (ORDERBY.toUpperCase() === "DATUM") {
      SORTORDER = SORTORDER === "DESC" ? "ASC" : "DESC";
    }
    let filter1;
    if (query.filter1) {
      let f1 = query.filter1.split(" ").join("");
      filter1 = f1.split(",");
    }
    const datum = [];
    if (query.datumV) {
      datum.push(query.datumV);
    }
    if (query.datumT) {
      datum.push(query.datumT);
    }
    const payload = {
      table: "DISPATCHRITTEN",
      ORDERBY: `${ORDERBY ? ORDERBY : ""} ${SORTORDER ? SORTORDER : ""}`,
      id: query.id || "",
      bedrijf: query.bedrijf,
      filter1,
      vluchtnr: query.vluchtnr || "",
      linkklant: query.linkklant || "",
      notlinkklant: query.notlinkklant || "",
      buitenland: query.buitenland || "",
      datum,
      find: query.find || "",
      soortwagen: query.soortwagen,
      combicomfort: query.combicomfort,
      nocombi: query.nocombi,
      geenoa: query.geenoa,
    };
    const responseData = await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to print dispatch ritten.",
    });
    return responseData.data;
  },

  clearRitten(context) {
    context.commit("setRitten", []);
    context.commit("setPages", 0);
    context.commit("setNumbers", null);
  },

  async freeSearch(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/search?q=${payload}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch freesearch results.",
    });
    return responseData.data;
  },

  async getBedrijven(context) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/leesbedrijven`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch dispatch bedrijven.",
    });
    context.commit("setBedrijven", responseData.data);
  },

  async getCustomers(context, payload = "") {
    context.commit("setCustomers", []);
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/leeshoofdklanten?q=${payload}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch dispatch hoofdklanten.",
    });
    context.commit("setCustomers", responseData.data);
  },

  async getActiveRit(context, payload) {
    //context.commit('setActiveRit', null);
    //context.commit('setActiveSubReizigers', []);
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/allsubreizigers?linkdispatch=${payload.ID}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch dispatch rit subreizigers.",
    });
    context.commit("setActiveRit", payload);
    context.commit("setActiveSubReizigers", responseData.data);
  },

  async getActiveRitDetail(context) {
    const token = context.rootGetters.token;
    const ritId = context.getters.activeRit.ID;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/orderdetail/${ritId}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch dispatch rit detail.",
    });
    return responseData.data;
  },

  clearActiveRit(context) {
    context.commit("setActiveRit", null);
    context.commit("setActiveSubReizigers", []);
  },

  async getAddressesClient(context, id) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/leesadressen/${id}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch dispatch klant adressen.",
    });
    return responseData.data;
  },
  async getFacturatieAddressesClient(context, id) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/leesfakturatieadressenklant?klantnr=${id}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch dispatch klant facturatie adressen.",
    });
    return responseData.data;
  },
  async getPersonAddressesClient(context, id) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/leesadressenreizigers/${id}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch dispatch klant reiziger adressen.",
    });
    return responseData.data;
  },

  async addDispatchRit(context, payload) {
    const token = context.rootGetters.token;
    payload.user = {
      id: context.rootGetters["user/userData"].id,
      name: context.rootGetters.userName,
    };
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/neworder`;
    const responseData = await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to add dispatch rit.",
    });
    return responseData.data;
  },

  async editDispatchRit(context, payload) {
    const token = context.rootGetters.token;
    payload.user = {
      id: context.rootGetters["user/userData"].id,
      name: context.rootGetters.userName,
    };
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/saveorder`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to edit dispatch rit.",
    });
  },

  async cloneRit(context, payload) {
    const token = context.rootGetters.token;
    payload.user = {
      id: context.rootGetters["user/userData"].id,
      name: context.rootGetters.userName,
    };
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/cloneRit`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to clone ride.",
    });
  },

  async deleteDispatchRit(context, { id }) {
    const token = context.rootGetters.token;
    const username = context.rootGetters.userName;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/wisrit/${id}?username=${username}`;
    await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to delete dispatch rit.",
    });
  },

  async toggleRitValue(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/setstatus`;
    payload.user = {
      id: context.rootGetters["user/userData"].id,
      name: context.rootGetters.userName,
    };
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to edit dispatch rit property.",
    });
  },

  async selectChauffeur(context, id) {
    const token = context.rootGetters.token;
    const user_id = context.rootGetters["user/userData"].id;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/selectchauffeur/${id}?user_id=${user_id}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to select rit chauffeurs.",
    });
    return responseData.data;
  },

  async selectGeenRit(context, datum) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/geenrit?datum=${datum}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to select geplande chauffeurs zonder rit.",
    });
    return responseData.data;
  },

  async planChauffeur(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/saveChauffeur`;
    payload.user = {
      id: context.rootGetters["user/userData"].id,
      name: context.rootGetters.userName,
    };
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to plan wagen/chauffeur.",
    });
  },

  async linkCarChauffeur(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/koppelWagenChauffeur`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to link wagen/chauffeur.",
    });
  },

  async removeChauffeur(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/removeChauffeur`;
    payload.user = {
      id: context.rootGetters["user/userData"].id,
      name: context.rootGetters.userName,
    };
    const responseData = await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to remove wagen/chauffeur.",
    });
    return responseData.data;
  },

  async saveReason(context, payload) {
    const token = context.rootGetters.token;
    payload.user = {
      id: context.rootGetters["user/userData"].id,
      name: context.rootGetters.userName,
    };
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/removeFixedDriver`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to save reason.",
    });
  },

  async saveEvaluatie(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/saveEvaluatie`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to save evaluation.",
    });
  },

  async assignBusNr(context, { id, idwagen }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/assignbusnr/${id}?idwagen=${idwagen}`;
    await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to assign busnr.",
    });
  },

  async readAlarm(context) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/readalarm`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to get alarm data.",
    });
    context.commit("setAlarm", responseData.data);
  },

  storeChauffeur(context, payload) {
    context.commit("setLastChauffeur", payload);
  },

  async getChauffeurs(context, { van, tot }) {
    context.dispatch("clearChauffeurs");
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/geplandechauffeurs?van=${van}&tot=${tot}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to get planned chauffeurs.",
    });
    context.commit("setChauffeurs", responseData.data);
  },

  async getChauffeurData(context, { templinkchauffeur, van, tot }) {
    context.dispatch("clearActiveChauffeur");
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/rittenchauffeur?templinkchauffeur=${templinkchauffeur}&van=${van}&tot=${tot}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to get ritten geplande chauffeur.",
    });
    context.commit("setActiveChauffeur", responseData.data);
  },

  async printChauffeurData(context, { id, van, tot, template }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/preview/${id}?template=${template}&van=${van}&tot=${tot}&pdf=true`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to print ritten geplande chauffeur.",
    });
    return responseData.data;
  },

  clearChauffeurs(context) {
    context.commit("setChauffeurs", []);
  },

  clearActiveChauffeur(context) {
    context.commit("setActiveChauffeur", null);
  },

  async sendSms(context, { id }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/smsrit/${id}`;
    await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to send sms to chauffeur.",
    });
  },

  async sendConfirmLinkAll(context, { van, tot }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/emailselfconfirmationlinkall?van=${van}&tot=${tot}`;
    await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to send self-confirmation link to all chauffeurs.",
    });
  },
  async sendConfirmLinkId(context, { van, tot, id }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/emailselfconfirmationlink/${id}?van=${van}&tot=${tot}`;
    await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to send self-confirmation link to selected chauffeur.",
    });
  },

  async sendAppConfirmLinkAll(context, { van, tot }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/appselfconfirmationlinkall?van=${van}&tot=${tot}`;
    await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to send app self-confirmation link to all chauffeurs.",
    });
  },
  async sendAppConfirmLinkId(context, { van, tot, id }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/appselfconfirmationlink/${id}?van=${van}&tot=${tot}`;
    await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg:
        "Failed to send app self-confirmation link to selected chauffeur.",
    });
  },

  async sendRittenBlad(context, { linkklant, templinkchauffeur, datum }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/emailrittenbladdelijn?linkklant=${linkklant}&templinkchauffeur=${templinkchauffeur}&datum=${datum}`;
    await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to mail rittenblad.",
    });
  },

  async getVluchtnrs(context, { datum }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/lijstvluchtnummers?datum=${datum}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to get vluchtnummers (van datum).",
    });
    return responseData;
  },

  async getRitRoute(context, { id }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/hesprice/googleroutecorrected/${id}?table=dispatchritten`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to get route data (invalid or incomplete data).",
    });
    return responseData;
  },
  async getRitContract(context, { id }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/preview/${id}?template=contract&pdf=true`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to print contract.",
    });
    return responseData.data;
  },
  async getRitHistory(context, { id }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/leeswijzigingen/${id}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to get rit historiek.",
    });
    return responseData.data;
  },

  async getOnderaannemers(context, { vip = "" }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/lijstonderaannemers?vip=${vip}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to get onderaannemers.",
    });
    return responseData.data;
  },
  async findOA(context, { id }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/searchoa/${id}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to find OA.",
    });
    return responseData.data;
  },

  async billoCombiOp1oa(context, { date1, date2, nightshift }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/billoCombiOp1oa?date1=${date1}&date2=${date2}&nightshift=${nightshift}`;
    await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to set billo combi op 1 OA.",
    });
  },
  async billoCombiOp1oaCancel(context, { date1, date2, nightshift }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/billoCombiOp1oaCancel?date1=${date1}&date2=${date2}&nightshift=${nightshift}`;
    await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to cancel billo combi op 1 OA.",
    });
  },
  async billoComfortOp1oa(context, { date1, date2, nightshift }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/billoComfortOp1oa?date1=${date1}&date2=${date2}&nightshift=${nightshift}`;
    await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to set billo comfort op 1 OA.",
    });
  },
  async billoComfortOp1oaCancel(context, { date1, date2, nightshift }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/billoComfortOp1oaCancel?date1=${date1}&date2=${date2}&nightshift=${nightshift}`;
    await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to cancel billo comfort op 1 OA.",
    });
  },
  async nietGeplandOp2oa(context, { date1, date2, nightshift }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/nietGeplandOp2oa?date1=${date1}&date2=${date2}&nightshift=${nightshift}`;
    await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to set niet-gepland op 2 OA.",
    });
  },
  async nietGeplandOp2oaCancel(context, { date1, date2, nightshift }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/nietGeplandOp2oaCancel?date1=${date1}&date2=${date2}&nightshift=${nightshift}`;
    await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to cancel niet-gepland op 2 OA.",
    });
  },
  async verstuurPlanning(context, payload) {
    const token = context.rootGetters.token;
    payload.user = {
      id: context.rootGetters["user/userData"].id,
      name: context.rootGetters.userName,
    };
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/verstuurPlanning`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to send planning.",
    });
  },

  async getAttendanceList(context, { date }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/aanwezigheidslijst?date=${date}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch aanwezigheidslijst.",
    });
    return responseData.data;
  },

  async getAttendancePeriodList(context, { from, until, delijn = 0 }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/planning/aanwezigheidslijstperiode?from=${from}&until=${until}&delijn=${delijn}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch periodieke aanwezigheidslijst.",
    });
    return responseData.data;
  },

  async getAvailableDriverList(context) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/chauffeursvrijgemeld`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch vrije chauffeurs lijst.",
    });
    return responseData.data;
  },

  async getRestList(context, { date }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/chauffeursrustdag?date=${date}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch lijst chauffeurs rustdag.",
    });
    return responseData.data;
  },

  async emailRestList(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/emailchauffeursrustdag`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to mail lijst chauffeurs rustdag.",
    });
  },

  async getTasks(context, { status }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/listtodo?status=${status}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch tasks.",
    });
    context.commit("setTasks", responseData.data);
  },
  async addTask(context, payload) {
    payload.creator = context.rootGetters["user/userData"].id;
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/addTodo`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to add task.",
    });
  },
  async editTask(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/editTodo`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to edit task.",
    });
  },
  async delTask(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/delTodo`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to delete task.",
    });
  },
  async getAttachments(context, { id }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/rideattachments/${id}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch attachments.",
    });
    return responseData.data;
  },
  async addAttachment(context, payload) {
    payload.creator = context.rootGetters["user/userData"].id;
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/addAttachment`;
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const responseData = await response.json();
    if (!response.ok || !responseData.status) {
      const error = new Error(
        responseData.error_description || "Failed to add document."
      );
      throw error;
    }
  },
  async delAttachment(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/deleteAttachment`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to delete attachment.",
    });
  },

  async getFreeChauffeurs(context, { datum }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/geenwerk?datum=${datum}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch chauffeur list.",
    });
    return responseData.data;
  },

  async getInsurance(context, { nrplaat }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/verzekeringwagen?nrplaat=${nrplaat}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch insurance.",
    });
    return responseData.data;
  },

  async getPin(context, { nrplaat }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/pincodetankkaart?nrplaat=${nrplaat}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch pin.",
    });
    return responseData.data;
  },

  async getAllPins(context) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/pincodestankkaarten`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch pins.",
    });
    return responseData.data;
  },

  async getKeuringData(context, bedrijf) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/tekeurenwagens?bedrijf=${bedrijf}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch te keuren wagens.",
    });
    return responseData;
  },

  async sendNotDone(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/emailRitNietUitgevoerd`;
    const responseData = await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to send email.",
    });
    return responseData.status;
  },

  async listCarsDrivers(context) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/listcarsdrivers`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch list.",
    });
    return responseData.data;
  },

  async ride2fms(context, { id, spoed }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/ride2fms/${id}?spoed=${spoed}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch list.",
    });
    return responseData.status;
  },
};
