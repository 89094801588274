<template>
    <div class="page home">
        <div v-if="user" class="home__welcome">{{$t('home.welcome')}} {{user.naam}}!</div>
    </div>
</template>

<script>
    export default {
        name: 'Home',
        computed:{
            user(){
                return this.$store.getters['user/userData'];
            }
        }
    }
</script>
