import apihelper from "@/helpers/apihelper";

export default{
    async getCenters(context){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/leescenters`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch centers.'});
        context.commit('setCenters', responseData.data);
    },

    async getTravelAgents(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/listtravelagents`;
        const query = payload.query;
        const data = {
            PageNumber: payload.PageNumber,
            PageSize: payload.PageSize,
            talic: query.talic || "",
            center: query.center|| "",
            tanaam: query.tanaam || "",
            taemail: query.taemail || "",
            tastraat: query.tastraat || "",
            tapostnr: query.tapostnr || "",
            tastad: query.tastad || "",
            ORDERBY: payload.sortField || "",
            SORTORDER: payload.sortOrder
        };
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(data),
            errorMsg: 'Failed to fetch agents.'
        });
        context.commit('setAgents', responseData.data);
        context.commit('setPages', Math.ceil(responseData.metadata.count / payload.PageSize));
    },

    clearTravelAgents(context){
        context.commit('setAgents', []);
        context.commit('setPages', 0);
    },

    async addTravelAgent(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/newtalic`;
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to add agent.'
        });
        return responseData.data;
    },

    async editTravelAgent(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/wijzigtalic`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to edit agent.'
        });
    },

    async getTravelAgent(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/leesprofile?talic=${payload}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch agent details.'});
        return responseData.data;
    },

    async getTravelAgentUsers(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/leesmedewerkers?talic=${payload}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch agent users.'});
        return responseData.data;
    },

    async addTravelAgentUser(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/newmedewerker`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to add agent user.'
        });
    },

    async editTravelAgentUser(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/travelagents/savemedewerker`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to edit agent user.'
        });
    }
}