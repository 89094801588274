export const ObjectMixin = {

    getObjectDifference: function(obj1,obj2){
        const o1 = JSON.parse(JSON.stringify(obj1));
        const o2 = JSON.parse(JSON.stringify(obj2));
        let diff = Object.keys(o2).reduce((diff, key) => {
            const key1 = o1[key]?o1[key].toString():"";
            const key2 = o2[key]?o2[key].toString():"";
            if (key1 === key2) return diff;
            return {
                ...diff,
                [key]: o2[key]
            }}, {});
        return diff;
    },

    convertObjectKeyValue: function(obj, old){
        return Object.keys(obj).map(a => a.toUpperCase()==='DATUM'?({fieldname:a,newvalue:this.formatDateSql(obj[a]),oldvalue:this.formatDateSql(old[a])}):({fieldname:a,newvalue:obj[a],oldvalue:old[a]}));
    },

    formatDateSql: function(date){
        const dateParts = date.split('/');
        if(dateParts.length !== 3){
            return date;
        }
        return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
    },

    formatDateSqlString: function(date){
        const year = date.getFullYear();
        const month = date.getMonth()+1;
        const days = date.getDate();
        return `${year}/${month>9?month:'0'+month}/${days>9?days:'0'+days}`;
    },

    formatDateFullSqlString: function(date){
        return `${date.getFullYear().toString().padStart(4, '0')}-${
            (date.getMonth()+1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')} ${
            date.getHours().toString().padStart(2, '0')}:${
            date.getMinutes().toString().padStart(2, '0')}:${
            date.getSeconds().toString().padStart(2, '0')}`
    },

    isJsonString: function(str) {
        try {
            const json = JSON.parse(str);
            return (typeof json === 'object');
        } catch (e) {
            return false;
        }
    },

    formatDateStringToTime: function(datestring){
        const date = new Date(datestring);
        const h = date.getHours();
        const m = date.getMinutes();
        return `${h>9?h:`0${h}`}:${m>9?m:`0${m}`}`;
    }
};
