export default {
    customers(state){
        return state.customers;
    },
    hasCustomers(state){
        return state.customers && state.customers.length;
    },

    pages(state){
        return state.pages;
    }
}