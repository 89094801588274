<template>
  <div class="modal attendance">
    <div class="modal__wrapper">
      <button class="close modal__close" @click="$emit('on-close')">
        <fa-icon icon="times"></fa-icon>
      </button>
      <div class="modal__title">Vrije chauffeurs (einde shift)</div>
      <div class="modal__body">
        <p v-if="loading">Loading...</p>
        <div
          id="attendance"
          class="attendance__data"
          v-if="freeData && freeData.length"
        >
          <table class="table">
            <thead>
              <tr class="table__header">
                <th
                  class="table__header__column"
                  v-for="key in Object.keys(freeData[0])"
                  :key="`free-header--${key}`"
                >
                  {{ key }}
                </th>
              </tr>
            </thead>
            <tbody class="table__body">
              <tr
                class="table__body__item"
                v-for="(item, index) in freeData"
                :key="`free-item--${index}`"
              >
                <td
                  class="table__body__item__column"
                  v-for="key in Object.keys(item)"
                  :class="{ work: item[key] === 'W' }"
                  :key="`free--${index}-${key}`"
                >
                  {{ item[key] }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FreeDrivers",

  data() {
    return {
      freeData: null,
      loading: true,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      this.freeData = [];
      try {
        this.freeData = await this.$store.dispatch(
          "dispatch/getAvailableDriverList"
        );
      } catch (e) {
        alert(e);
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
</style>
