import apihelper from "@/helpers/apihelper";

export default {
  async getRitten(
    context,
    { PageNumber, PageSize, query, ORDERBY, SORTORDER }
  ) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/historiek/leesrittenscherm`;
    if (ORDERBY.toUpperCase() === "DATUM") {
      SORTORDER = "";
    }
    let filter1;
    if (query.filter1) {
      let f1 = query.filter1.split(" ").join("");
      filter1 = f1.split(",");
    }
    const datum = [];
    if (query.datumV) {
      datum.push(query.datumV);
    }
    if (query.datumT) {
      datum.push(query.datumT);
    }
    const payload = {
      PageNumber,
      PageSize,
      table: "HISTORIEKRITTEN",
      ORDERBY: `${ORDERBY ? ORDERBY : ""} ${SORTORDER ? SORTORDER : ""}`,
      id: query.id || "",
      bedrijf: query.bedrijf,
      filter1,
      linkklant: query.linkklant || "",
      datum,
      find: query.find || "",
      po: query.po || "",
      templinkchauffeur: query.templinkchauffeur || "",
      busritten: query.busritten,
      betalingswijze: query.betalingswijze,
      nrplaat: query.nrplaat || "",
      gewist: query.gewist ? [0, 1] : [0],
      lisopdrachtid: query.lisopdrachtid ? query.lisopdrachtid : "",
    };
    const responseData = await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to fetch historiek ritten.",
    });
    context.commit("setRitten", responseData.data);
    context.commit("setNumbers", {
      count: responseData.metadata.count,
      aantallen: responseData.metadata.aantallen,
    });
    context.commit(
      "setPages",
      Math.ceil(responseData.metadata.count / responseData.metadata.PageSize)
    );
  },

  async getBedrijven(context) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/historiek/leesbedrijven`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch historiek bedrijven.",
    });
    context.commit("setBedrijven", responseData.data);
  },

  async printRitten(context, { query, ORDERBY, SORTORDER }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/historiek/printrittenscherm`;
    let filter1;
    if (query.filter1) {
      let f1 = query.filter1.split(" ").join("");
      filter1 = f1.split(",");
    }
    const datum = [];
    if (query.datumV) {
      datum.push(query.datumV);
    }
    if (query.datumT) {
      datum.push(query.datumT);
    }
    const payload = {
      table: "HISTORIEKRITTEN",
      ORDERBY: `${ORDERBY ? ORDERBY : ""} ${SORTORDER ? SORTORDER : ""}`,
      id: query.id || "",
      bedrijf: query.bedrijf,
      filter1,
      linkklant: query.linkklant || "",
      datum,
      po: query.po || null,
      templinkchauffeur: query.templinkchauffeur || null,
      busritten: query.busritten,
      betalingswijze: query.betalingswijze,
      nrplaat: query.nrplaat || null,
      keuzevelden: query.keuzevelden,
      gewist: query.gewist ? [0, 1] : [0],
      listopdrachtid: query.listopdrachtid ? query.listopdrachtid : "",
    };
    const responseData = await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to print historiek ritten.",
    });
    return responseData.data;
  },

  async exportRitten(context, { query, ORDERBY, SORTORDER }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/historiek/exportrittenscherm`;
    let filter1;
    if (query.filter1) {
      let f1 = query.filter1.split(" ").join("");
      filter1 = f1.split(",");
    }
    const datum = [];
    if (query.datumV) {
      datum.push(query.datumV);
    }
    if (query.datumT) {
      datum.push(query.datumT);
    }
    const payload = {
      table: "HISTORIEKRITTEN",
      ORDERBY: `${ORDERBY ? ORDERBY : ""} ${SORTORDER ? SORTORDER : ""}`,
      id: query.id || "",
      bedrijf: query.bedrijf,
      filter1,
      linkklant: query.linkklant || "",
      datum,
      po: query.po || "",
      templinkchauffeur: query.templinkchauffeur || "",
      busritten: query.busritten,
      betalingswijze: query.betalingswijze,
      nrplaat: query.nrplaat || "",
      keuzevelden: query.keuzevelden,
      gewist: query.gewist ? [0, 1] : [0],
      listopdrachtid: query.listopdrachtid ? query.listopdrachtid : "",
    };
    const responseData = await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to export historiek ritten.",
    });
    return responseData.data;
  },

  clearRitten(context) {
    context.commit("setRitten", []);
    context.commit("setPages", 0);
    context.commit("setNumbers", null);
  },

  async freeSearch(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/historiek/search?q=${payload}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch freesearch results.",
    });
    return responseData.data;
  },

  async sendReceipt(context, { id, email }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/historiek/verstuurkwitantie/${id}?email=${email}`;
    await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to send kwitantie.",
    });
  },

  async getRemark(context, { id }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/historiek/leesopmfactuur/${id}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to get rit opmerking factuur.",
    });
    return responseData.data;
  },
  async saveRemark(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/historiek/wijzigOpmerkingFactuur`;
    const responseData = await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to save rit opmerking factuur.",
    });
    return responseData.data;
  },

  async toDispatch(context, { id }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/historiek/naardispatch/${id}`;
    await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to convert history rit to dispatch rit.",
    });
  },

  async getRitHistory(context, { id }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/historiek/leeswijzigingen/${id}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to get rit historiek.",
    });
    return responseData.data;
  },
};
