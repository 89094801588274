<template>
  <div class="navigation" v-if="user && user.rights" :class="{'navigation--visible':visible}">
    <div class="navigation__info">
      <a href="doc/Handleiding-dispatch.pdf" target="_blank" :title="$t('navigation.manual')"><fa-icon icon="question-circle"></fa-icon></a>
    </div>
    <div class="title navigation__title">{{ $t('navigation.title') }}</div>
    <router-link class="navigation__link" to="/billo-helpdesk"
                 @click.native="visible=false"
                 v-if="user.rights.billo_helpdesk">
      {{ $t('navigation.bh') }}
    </router-link>
    <a class="navigation__link"
       href="https://www.billo.be/planningbillo"
       target="_blank" v-if="user.rights.billo_helpdesk">{{ $t('navigation.planning') }}</a>
    <!--<router-link class="navigation__link" to="/vip-drive" @click.native="visible=false" v-if="user.rights.vip_helpdesk">{{ $t('navigation.vd') }}
    </router-link>-->
    <router-link class="navigation__link" to="/dispatch" @click.native="visible=false" v-if="user.rights.dispatch">{{ $t('navigation.dp') }}
    </router-link>
    <router-link class="navigation__link" to="/history" @click.native="visible=false" v-if="user.rights.dispatch">{{ $t('navigation.history') }}
    </router-link>
    <router-link class="navigation__link" to="/combination" @click.native="visible=false" v-if="user.rights.combinatieritten">{{ $t('navigation.combi') }}</router-link>
    <router-link class="navigation__link" to="/vouchers" @click.native="visible=false" v-if="user.rights.vouchers">{{ $t('navigation.v') }}</router-link>
    <router-link class="navigation__link" to="/customers" @click.native="visible=false" v-if="user.rights.klanten">{{ $t('navigation.cust') }}</router-link>
    <router-link class="navigation__link" to="/travelagents" @click.native="visible=false" v-if="user.rights.travelagents">{{ $t('navigation.ta') }}
    </router-link>
    <router-link class="navigation__link" to="/planning" @click.native="visible=false">Planning</router-link>
    <router-link class="navigation__link" to="/invoicing" @click.native="visible=false" v-if="user.rights.vip_facturatie">Facturatie</router-link>
    <router-link class="navigation__link" to="/intranet-users" @click.native="visible=false" v-if="user.rights.intranet_users">{{ $t('navigation.users') }}</router-link>
    <div class="navigation__link" @click="$emit('support-clicked')">{{ $t('navigation.support') }}</div>
    <div class="navigation__alarms" v-if="alarm">
      <div class="navigation__alarms__alarm" :class="{'navigation__alarms__alarm--red':tasksAlert}" :title="$t('navigation.tasks')" @click="$emit('tasks-clicked')">
        <fa-icon icon="tasks"></fa-icon>
      </div>
      <div class="navigation__alarms__alarm navigation__alarms__alarm--spoed" :title="`${alarm.aantalspoed} ${$t('navigation.urgent')}`" :class="{'navigation__alarms__alarm--green':alarm.spoedalarm==='green',
      'navigation__alarms__alarm--red':alarm.spoedalarm==='red',
      'navigation__alarms__alarm--yellow':alarm.spoedalarm==='yellow'}">
        <fa-icon icon="exclamation-triangle"></fa-icon>
      </div>
      <div class="navigation__alarms__alarm navigation__alarms__alarm--combi" :title="$t('navigation.combiStatus')" @click="$emit('combi-clicked')" :class="{'navigation__alarms__alarm--green':alarm.combinatiealarm==='green',
      'navigation__alarms__alarm--red':alarm.combinatiealarm==='red',
      'navigation__alarms__alarm--yellow':alarm.combinatiealarm==='yellow'}">
        <fa-icon icon="project-diagram"></fa-icon>
      </div>
      <div class="navigation__alarms__alarm navigation__alarms__alarm--sms"  :title="$t('navigation.smsStatus')" @click="$emit('sms-clicked')" :class="{'navigation__alarms__alarm--green':alarm.smsalarm==='green',
      'navigation__alarms__alarm--red':alarm.smsalarm==='red',
      'navigation__alarms__alarm--yellow':alarm.smsalarm==='yellow'}">
        <fa-icon icon="mobile-alt"></fa-icon>
      </div>
      <div class="navigation__alarms__alarm navigation__alarms__alarm--map" :title="$t('navigation.carChart')" @click="$emit('map-clicked')">
        <fa-icon icon="map"></fa-icon>
      </div>
      <div class="navigation__alarms__alarm navigation__alarms__alarm--chauffeur" :title="$t('navigation.attendance')" @click="$emit('chauffeur-clicked')">
        <fa-icon icon="user-tie"></fa-icon>
      </div>
      <div class="navigation__alarms__alarm navigation__alarms__alarm--chauffeur" title="Aanwezigheden periode" @click="$emit('attendance-clicked')">
        <fa-icon icon="calendar"></fa-icon>
      </div>
      <div class="navigation__alarms__alarm navigation__alarms__alarm--chauffeur" title="Vrije chauffeurs (einde shift)" @click="$emit('free-clicked')">
        <fa-icon icon="user-check"></fa-icon>
      </div>
      <LanguageSelector />
    </div>
    <div class="navigation__toggle" @click="visible=!visible">
      <fa-icon icon="chevron-left" v-if="visible"></fa-icon>
      <fa-icon icon="chevron-right" v-else></fa-icon>
    </div>
    <div class="navigation__version">
      <span>v.{{ version }}</span>
    </div>
    <div class="navigation__overlay" @click="visible=false"></div>
  </div>
</template>

<script>
import LanguageSelector from "@/components/LanguageSelector";
export default {
  name: "Navigation",

  props:{
    alarm:{},
    tasks:{}
  },

  components:{
    LanguageSelector
  },

  data() {
    return {
      visible: true,
      version: process.env.VERSION
    };
  },

  computed:{
    user(){
      return this.$store.getters['user/userData'];
    },
    tasksAlert(){
      return this.tasks.some(t => t.status !== 'DONE');
    }
  }
}
</script>