export default{
    ritten(state){
        return state.ritten;
    },

    hasRitten(state){
        return state.ritten && state.ritten.length;
    },

    numbers(state){
        return state.numbers
    },

    pages(state){
        return state.pages;
    },

    wagens(state){
        return state.wagens;
    },

    bedrijven(state){
        return state.bedrijven;
    },

    bedrijfitems(state) {
        return state.bedrijven.map(b => b.BEDRIJF);
    },

    customers(state){
        return state.customers;
    },

    activeRit(state){
        return state.activeRit
    },

    activeSubReizigers(state) {
        return state.activeSubReizigers;
    },

    alarm(state){
        return state.alarm;
    },

    lastChauffeur(state) {
        return state.lastChauffeur;
    },

    activeChauffeur(state){
        return state.activeChauffeur;
    },
    chauffeurs(state){
        return state.chauffeurs;
    },
    tasks(state){
        return state.tasks;
    },
    lijnNumbers(state){
        return state.LIJN;
    }

}