export default{
    ritten(state){
        return state.ritten;
    },

    hasRitten(state){
        return state.ritten && state.ritten.length;
    },

    numbers(state){
        return state.numbers
    },

    pages(state){
        return state.pages;
    },

    bedrijven(state){
        return state.bedrijven;
    },

    bedrijfitems(state) {
        return state.bedrijven.map(b => b.BEDRIJF);
    }
}