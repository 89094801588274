export default {
    setCenters(state, payload){
        state.centers = payload;
    },

    setAgents(state, payload){
        state.agents = payload;
    },

    setPages(state, payload){
        state.pages = payload;
    }
}