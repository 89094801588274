export default{
    setRitten(state, payload){
        state.ritten = payload;
    },

    setNumbers(state,payload){
        state.numbers = payload;
    },

    setPages(state, payload){
        state.pages = payload;
    },

    setBedrijven(state, payload){
        state.bedrijven = payload;
    }
}