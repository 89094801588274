export default {
    async fetchPostRequest({url, token, payload, errorMsg}){
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: payload
        });
        return await this.handleResponseData(response, errorMsg);
    },

    async fetchGetRequest({url, token, errorMsg}){
        const response = await fetch(url,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        return await this.handleResponseData(response, errorMsg);
    },

    async handleResponseData(response, errorMsg){
        const responseData = await response.json();
        if (!response.ok || !(responseData.status||responseData.result)) {
            const error = new Error(
                responseData.error || responseData.error_description || errorMsg
            );
            throw error;
        }
        return responseData;
    }
}