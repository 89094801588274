<template>
<div class="modal attendance">
  <div class="modal__wrapper">
    <button class="close modal__close" @click="$emit('on-close')">
      <fa-icon icon="times"></fa-icon>
    </button>
    <div class="modal__title">{{ $t('attendance.title') }}</div>
    <div class="modal__body">
      <div class="attendance__picker">
        <label>Datum</label>
        <date-picker id="date-attendance" v-model="date" type="date" format="DD/MM/YYYY" :clearable="false"
                        value-type="YYYY/MM/DD"></date-picker>
        <button class="button" v-if="attendanceData && attendanceData.length" @click="printData">{{ $t('printList') }}</button>
      </div>
      <div id="attendance" class="attendance__data" v-if="attendanceData && attendanceData.length">
        <table class="table">
          <thead>
          <tr class="table__header">
            <th class="table__header__column" v-for="key in Object.keys(attendanceData[0])"
                :key="`attendance-header--${key}`">
              {{ key }}
            </th>
          </tr>
          </thead>
          <tbody class="table__body">
          <tr class="table__body__item" v-for="(item,index) in attendanceData"
              :key="`attendance-item--${index}`">
            <td class="table__body__item__column"
                v-for="key in Object.keys(item)"
                :key="`attendance--${index}-${key}`">
              {{ item[key] }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {ObjectMixin} from "@/mixins/ObjectMixin";
import DatePicker from 'vue2-datepicker';

export default {
  name: "Attendance",

  components: {
    DatePicker
  },

  data(){
    return {
      date: ObjectMixin.formatDateSqlString(new Date()),
      attendanceData: null
    }
  },

  created(){
    this.fetchData();
  },

  watch:{
    date(){
      this.fetchData();
    }
  },

  methods:{
    async fetchData(){
      this.attendanceData = [];
      try{
        this.attendanceData = await this.$store.dispatch('dispatch/getAttendanceList',{date:this.date});
      } catch(e){
        alert(e);
      }
    },
    printData(){
      const divContents = document.getElementById("attendance").innerHTML;
      const a = window.open('', '', 'height=500, width=500');
      a.document.write(`<head><title>Aanwezigheidslijst - ${ObjectMixin.formatDateSql(this.date)}</title><style>body{font-family: Arial, sans-serif}h1{margin-bottom:16px;}table,tr,td,th{border-collapse:collapse;border:1px #000 solid;}td,th{padding:4px 8px;} th{text-transform: uppercase;}</style></head>`)
      a.document.write('<html>');
      a.document.write(`<body><h1>Aanwezigheidslijst - ${ObjectMixin.formatDateSql(this.date)}<br>`);
      a.document.write(divContents);
      a.document.write('</body></html>');
      a.document.close();
      a.print();
      a.close();
    }
  }
}
</script>