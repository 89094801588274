<template>
<div class="track-map">
  <button class="close track-map__close" @click="$emit('on-close')">
    <fa-icon icon="times"></fa-icon>
  </button>
  <div class="track-map__map" id="tracking-map">
  </div>
  <div class="track-map__data">
    <h1>{{ $t('trackMap.cars') }}</h1>
    <button class="button button--accept" @click="showCar(false)" :class="{'button--disabled':!activePoint}" :disabled="!activePoint">{{ $t('showAll') }}</button>
    <div class="track-map__data__results" v-if="carData && carData && carData.length">
      <table class="table">
        <thead>
        <tr class="table__header">
          <th class="table__header__column">{{ $t('data.time') }}</th>
          <th class="table__header__column">{{ $t('data.plate') }}</th>
          <th class="table__header__column">{{ $t('data.chaufId') }}</th>
          <th class="table__header__column">{{ $t('data.chauf') }}</th>
          <th class="table__header__column">{{ $t('data.lat') }}</th>
          <th class="table__header__column">{{ $t('data.lon') }}</th>
          <th class="table__header__column">{{ $t('data.speed') }}</th>
        </tr>
        </thead>
        <tbody class="table__body">
        <tr class="table__body__item" :class="{'table__body__item--active': item.nrplaat==activePoint}" v-for="(item,index) in carData"
            :key="`car-item--${index}`" @click="showCar(item.nrplaat)">
          <td class="table__body__item__column">{{ item.time }}</td>
          <td class="table__body__item__column">{{ item.nrplaat }}</td>
          <td class="table__body__item__column">{{ item.driver }}</td>
          <td class="table__body__item__column">{{ item.naam }}</td>
          <td class="table__body__item__column">{{ item.lat }}</td>
          <td class="table__body__item__column">{{ item.lon }}</td>
          <td class="table__body__item__column">{{ item.speed }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>

<script>
import MapsMixin from "@/mixins/MapsMixin";
//import {faCar as icon} from "@fortawesome/free-solid-svg-icons";
export default {
  name: "TrackMap",
  mixins: [MapsMixin],
  data() {
    return {
      map: null,
      carData: null,
      activePoint: null,
      markers: [],

      refreshTimeout: null
    };
  },
  mounted() {
    MapsMixin.checkMapsLoaded(this.initMap);
  },

  beforeDestroy() {
    clearTimeout(this.refreshTimeout);
    this.markers.forEach(m => {
      m.setMap(null);
      m = null;
    });
  },

  methods:{
    initMap() {
      const el = document.getElementById('tracking-map');
      if (!el) {
        return;
      }
      this.map = new google.maps.Map(el, {
        center: {lat: 50.84746749849417, lng:4.351691934152209},
        zoom:9,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });
      this.getCarData();
    },

    async getCarData(){
      try{
        this.carData = await this.$store.dispatch('common/getAllCarsData');
        this.drawCars();
        this.refreshTimeout = setTimeout(async ()=>{
          await this.getCarData();
        }, 10 * 60 * 1000);
      }catch(e){
        alert(e);
      }
    },

    drawCars(){
      const cars = this.carData;
      if(!cars){
        return;
      }
      cars.forEach(c => {
        const latlng = {lat: parseFloat(c.lat/1000000), lng: parseFloat(c.lon/1000000)};
        let marker = this.markers.find(m => m.id===c.nrplaat);
        if (!marker) {
          marker = new google.maps.Marker({
            id: c.nrplaat,
            label:{
              text:`${c.nrplaat}${c.naam?` -- ${c.naam}`:" -- onbekend"}`,
              color: "black",
              fontWeight: "bold"
            },
            position: latlng,
            title: `${c.nrplaat}${c.naam?` -- ${c.naam}`:" -- onbekend"}`,
            map: this.map,
          });
          marker.addListener("click", () => {
            this.showCar(c.nrplaat);
          });
          this.markers.push(marker);
        } else {
          marker.setPosition(latlng);
        }
      });
    },

    showCar(nrplaat){
      this.activePoint = nrplaat;
      this.markers.forEach(m => m.setVisible(!this.activePoint));
      if(this.activePoint){
        const marker = this.markers.find(m => m.id == nrplaat);
        if(marker){
          marker.setVisible(true);
          this.map.panTo(marker.position);
        }
      }
      else{
        this.map.panTo({lat: 50.84746749849417, lng:4.351691934152209});
      }
    }
  }


}
</script>