<template>
  <div class="modal attendance">
    <div class="modal__wrapper">
      <button class="close modal__close" @click="$emit('on-close')">
        <fa-icon icon="times"></fa-icon>
      </button>
      <div class="modal__title">{{ $t("attendance.title") }} Periode</div>
      <div class="modal__body">
        <div class="attendance__picker">
          <label>Van</label>
          <date-picker
            id="date-attendance"
            v-model="search.from"
            type="date"
            format="DD/MM/YYYY"
            :clearable="false"
            value-type="YYYY/MM/DD"
            @change="fetchData"
          ></date-picker>
          <label>Tot</label>
          <date-picker
            id="until-attendance"
            v-model="search.until"
            type="date"
            format="DD/MM/YYYY"
            :clearable="false"
            value-type="YYYY/MM/DD"
            @change="fetchData"
          ></date-picker>
        </div>
        <div
          id="attendance"
          class="attendance__data"
          v-if="attendanceData && attendanceData.length"
        >
          <table class="table">
            <thead>
              <tr class="table__header">
                <th
                  class="table__header__column"
                  v-for="key in Object.keys(attendanceData[0])"
                  :key="`attendance-header--${key}`"
                >
                  {{ key }}
                </th>
              </tr>
            </thead>
            <tbody class="table__body">
              <tr
                class="table__body__item"
                v-for="(item, index) in attendanceData"
                :key="`attendance-item--${index}`"
              >
                <td
                  class="table__body__item__column"
                  v-for="key in Object.keys(item)"
                  :class="{'work':item[key]==='W','no-work':item[key]==='OO'||item[key]==='V'||item[key]==='Z'}"
                  :key="`attendance--${index}-${key}`"
                >
                  {{ item[key] }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ObjectMixin } from "@/mixins/ObjectMixin";
import DatePicker from "vue2-datepicker";

let end = new Date();
end.setDate(end.getDate() + 14);

export default {
  name: "AttendancePeriod",

  components: {
    DatePicker,
  },

  data() {
    return {
      search: {
        from: ObjectMixin.formatDateSqlString(new Date()),
        until: ObjectMixin.formatDateSqlString(end),
      },
      attendanceData: null,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      this.attendanceData = [];
      try {
        this.attendanceData = await this.$store.dispatch(
          "dispatch/getAttendancePeriodList",
          { ...this.search }
        );
      } catch (e) {
        alert(e);
      }
    },
  },
};
</script>
<style scoped>
.work{
  background-color: #1ED760;
}
.no-work{
  background-color: #e34234;
}
</style>