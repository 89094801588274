export default{
    orders(state){
        return state.orders;
    },
    hasOrders(state){
        return state.orders && state.orders.length;
    },

    customers(state){
        return state.customers;
    },
    hasCustomers(state){
        return state.customers && state.customers.length;
    },

    ritten(state){
        return state.ritten;
    },
    hasRitten(state){
        return state.ritten && state.ritten.length;
    }
}