export default {
    setOrders(state, payload) {
        state.orders = payload;
    },

    setRitten(state, payload) {
        state.ritten = payload;
    },

    setCustomers(state,payload){
        state.customers = payload;
    }
}