<template>
  <div id="app" class="app">
    <user v-if="isAuthenticated"></user>
    <button class="logout" type="button" @click="logoutClicked" v-if="isAuthenticated" title="Log out">
      <fa-icon icon="power-off"></fa-icon>
    </button>
    <Navigation v-if="isAuthenticated" :alarm="alarm" :tasks="tasks"
                @combi-clicked="combiVisible=true"
                @sms-clicked="smsVisible=true"
                @map-clicked="mapVisible=true"
                @chauffeur-clicked="chauffeurVisible=true"
                @attendance-clicked="attendanceVisible=true"
                @tasks-clicked="tasksVisible=true"
                @free-clicked="freeVisible=true"
                @support-clicked="support={
                  title:'',
                 subject:'',
                 page:'',
                 text:''}"/>
    <router-view/>
    <div class="update" v-if="updateExists">
      <span>{{ $t('update.title') }}</span>
      <button class="button" @click="refreshApp">{{ $t('update.text') }}</button>
    </div>
    <AlarmCombi v-if="isAuthenticated && combiVisible" @on-close="combiVisible=false"/>
    <AlarmSms v-if="isAuthenticated && smsVisible" @on-close="smsVisible=false"/>
    <TrackMap v-if="isAuthenticated && mapVisible" @on-close="mapVisible=false"/>
    <Attendance v-if="isAuthenticated && chauffeurVisible" @on-close="chauffeurVisible=false"/>
    <AttendancePeriod v-if="isAuthenticated && attendanceVisible" @on-close="attendanceVisible=false"/>
    <FreeDrivers v-if="isAuthenticated && freeVisible" @on-close="freeVisible=false"/>
    <TaskOverview v-if="isAuthenticated && tasksVisible" @on-close="tasksVisible=false"/>
    <div class="modal support" v-if="isAuthenticated && support">
      <div class="modal__wrapper">
        <button class="close modal__close" @click="support=null">
          <fa-icon icon="times"></fa-icon>
        </button>
        <div class="modal__title">{{ $t('support.title') }}</div>
        <div class="modal__body">
          <p>{{ $t('support.text1') }}</p>
          <p>{{ $t('support.text2') }}</p>
          <p>{{ $t('support.text3') }}</p>
          <div class="formgroup">
            <label>{{ $t('support.summary') }}</label>
            <input type="text" class="input" v-model="support.title">
            <label>{{ $t('support.subject') }}</label>
            <select class="select" v-model="support.subject">
              <option :value="''" disabled>-- {{ $t('chose') }} {{ $t('support.subject') }} --</option>
              <option value="bug">{{ $t('support.bug') }}</option>
              <option value="suggestion">{{ $t('support.suggestion') }}</option>
              <option value="question">{{ $t('support.question') }}</option>
            </select>
            <label>{{ $t('support.module') }}</label>
            <select v-model="support.page" class="select">
              <option :value="''" disabled>-- {{ $t('chose') }} Intranet {{ $t('support.module') }} --</option>
              <option value="general">{{ $t('support.general') }}</option>
              <option v-if="user.rights.billo_helpdesk" value="billo-helpdesk">{{ $t('navigation.bh') }}</option>
              <option v-if="user.rights.billo_express" value="billo-express">{{ $t('navigation.be') }}</option>
              <option v-if="user.rights.billo_work" value="billo-work">Billo 2 Work</option>
              <option v-if="user.rights.vip_helpdesk" value="vip-pegase">{{ $t('navigation.vd') }}</option>
              <option v-if="user.rights.dispatch" value="dispatch">{{ $t('navigation.dp') }}</option>
              <option v-if="user.rights.combinatieritten" value="combinatie-ritten">{{ $t('navigation.combi') }}</option>
              <option v-if="user.rights.vouchers" value="vouchers">{{ $t('navigation.v') }}</option>
              <option v-if="user.rights.klanten" value="klanten">{{ $t('navigation.cust') }}</option>
              <option v-if="user.rights.travelagents" value="travelagents">{{ $t('navigation.ta') }}</option>
              <option v-if="user.rights.personeel" value="personeel" disabled>{{ $t('navigation.pers') }}</option>
            </select>
            <label>{{ $t('support.explanation') }}</label>
            <textarea class="textarea" cols="30" rows="10" v-model="support.text"
                      :placeholder="$t('support.explanation')"></textarea>
          </div>
        </div>
        <div class="modal__controls">
          <button class="button button--accept" @click="postIssue">{{ $t('send') }}</button>
        </div>
      </div>
    </div>
    <div class="modal" v-if="isSent">
      <div class="modal__wrapper">
        <button class="close modal__close" @click="isSent=false">
          <fa-icon icon="times"></fa-icon>
        </button>
        <div class="modal__title">{{ $t('support.sentTitle') }}</div>
        <div class="modal__body">
          <p>{{ $t('support.sentText') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import 'assets/sass/app';
</style>
<script>
import AlarmCombi from "@/components/AlarmCombi";
import AlarmSms from "@/components/AlarmSms";
import TrackMap from "@/components/TrackMap";
import Attendance from "@/components/Attendance";
import AttendancePeriod from "./components/AttendancePeriod.vue";
import FreeDrivers from "./components/FreeDrivers.vue";
import TaskOverview from "@/components/TaskOverview";
import update from "@/mixins/update";
import Navigation from "./components/Navigation";
import User from "./components/User";

export default {
  components: {
    Navigation,
    User,
    AlarmCombi,
    AlarmSms,
    TrackMap,
    Attendance,
    AttendancePeriod,
    FreeDrivers,
    TaskOverview
  },

  mixins: [update],
  computed: {
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated && this.$store.getters['user/userData'] && this.$store.getters['user/userData'].actief;
    },
    alarm() {
      return this.$store.getters['dispatch/alarm'];
    },
    tasks() {
      return this.$store.getters['dispatch/tasks'];
    },
    user() {
      return this.$store.getters['user/userData'];
    }
  },

  data() {
    return {
      alarmTimeout: null,
      combiVisible: false,
      smsVisible: false,
      mapVisible: false,
      chauffeurVisible: false,
      attendanceVisible: false,
      tasksVisible: false,
      freeVisible: false,
      support: null,
      isSent: false,
    }
  },

  watch: {
    isAuthenticated() {
      if (this.isAuthenticated) {
        this.readAlarm();
      } else {
        clearTimeout(this.alarmTimeout);
      }
    }
  },
  created() {
    this.tryLogin();
  },
  beforeDestroy() {
    clearTimeout(this.alarmTimeout);
  },

  methods: {
    async tryLogin() {
      try {
        await this.$store.dispatch('tryLogin');
      } catch (e) {
        alert(e);
      }
    },
    logoutClicked() {
      this.$store.dispatch('logout');
      this.$router.replace('/login');
    },

    async readAlarm() {
      try {
        await Promise.all([
          this.$store.dispatch('dispatch/readAlarm'),
          this.$store.dispatch('dispatch/getTasks', {status: ''}),
        ]);
        this.alarmTimeout = setTimeout(() => {
          this.readAlarm();
        }, 5 * 60 * 1000);
      } catch (e) {
        this.alarmTimeout = setTimeout(() => {
          this.readAlarm();
        }, 5 * 1000);
      }
    },

    async postIssue() {
      if (Object.keys(this.support).some(key => !this.support[key])) {
        alert('Vul alle velden in.');
        return;
      }
      try {
        await this.$store.dispatch('common/postIssue', this.support);
        this.support = false;
        this.isSent = true;
      } catch (e) {
        alert(e);
      }
    }
  }
}
</script>