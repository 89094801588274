import apihelper from "@/helpers/apihelper";

export default{
    async getVouchers(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billohelpdesk/findvouchers`;
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to fetch vouchers.'
        });
        context.commit('setVouchers', responseData.data);
        context.commit('setPages', Math.ceil(responseData.metadata.count / payload.PageSize));
    },

    clearVouchers(context){
        context.commit('setVouchers', []);
        context.commit('setPages', 0);
    },

    async getPromos(context){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billohelpdesk/lijstpromos`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch promos.'});
        context.commit('setPromos', responseData.data);
    },

    async editVoucher(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billohelpdesk/editvoucher`;
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to edit voucher.'
        });
        return responseData.data;
    },

    async addPoKorting(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billohelpdesk/addpokorting`;
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to add PO korting.'
        });
        return responseData.data;
    }
}