import Vue from 'vue';
import VueX from 'vuex';
import authModule from './modules/auth';
import userModule from './modules/user';
import commonModule from './modules/common';
import billoModule from './modules/billo';
import vipModule from './modules/vip';
import voucherModule from './modules/voucher';
import travelagentModule from './modules/travelagent';
import dispatchModule from './modules/dispatch';
import historyModule from './modules/history';
import combinationModule from './modules/combination';
import customerModule from './modules/customer';
import planningModule from './modules/planning';
import invoicingModule from './modules/invoicing';

Vue.use(VueX);

export const store = new VueX.Store({
    modules:{
        auth: authModule,
        user: userModule,
        common: commonModule,
        billo: billoModule,
        vip: vipModule,
        voucher: voucherModule,
        travelagent: travelagentModule,
        dispatch: dispatchModule,
        history: historyModule,
        combination: combinationModule,
        customer: customerModule,
        planning: planningModule,
        invoicing: invoicingModule
    }
});