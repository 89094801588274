import apihelper from "@/helpers/apihelper";

export default {
  async getCustomers(
    context,
    { PageNumber, PageSize, find, OrderBy, SortOrder }
  ) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/leesKlanten`;
    if (OrderBy.toUpperCase() === "DATUM") {
      SortOrder = SortOrder === "DESC" ? "ASC" : "DESC";
    }
    const payload = {
      PageNumber,
      PageSize,
      OrderBy: OrderBy || "",
      SortOrder,
      find: find || "",
    };
    const responseData = await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to fetch customers.",
    });
    context.commit("setCustomers", responseData.data);
    context.commit(
      "setPages",
      Math.ceil(responseData.metadata.count / payload.PageSize)
    );
  },

  clearCustomers(context) {
    context.commit("setCustomers", []);
    context.commit("setPages", 0);
  },

  async getCustomerDetail(context, id) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/detailklant/${id}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch customer detail.",
    });
    return responseData.data;
  },

  async getCustomerTarief(context, id) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/selecttarieven/${id}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch customer tarief.",
    });
    return responseData.data;
  },
  async getCustomerPrice(context, id) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/klantprice/${id}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch customer price.",
    });
    return responseData.data;
  },
  async getCustomerAddresses(context, id) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/leesadressen/${id}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch customer addresses.",
    });
    return responseData.data;
  },
  async getCustomerTravelerAddresses(context, id) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/leesadressenreizigers/${id}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch customer traveler addresses.",
    });
    return responseData.data;
  },
  async getCustomerInvoiceAddresses(context, id) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/leesfakturatieadressenklant?klantnr=${id}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch customer invoice addresses.",
    });
    return responseData.data;
  },
  async getCustomerRoundtripAddresses(context, id) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/selectroundtripaddresses/${id}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch customer roundtrip addresses.",
    });
    return responseData.data;
  },
  async getCustomerStaffelPrices(context, id) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/leesstaffelprijzen_klanten_id?cust_id=${id}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch customer staffel prices.",
    });
    return responseData.data;
  },
  async getCustomerPosttourPrices(context, id) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/selectposttourprijzen/${id}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch customer posttour prices.",
    });
    return responseData.data;
  },
  async getCustomerForfaitPrices(context, id) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/selectforfaitprijzen/${id}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch customer forfait prices.",
    });
    return responseData.data;
  },

  async saveCustomer(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/editCustomer`;
    payload.user = {
      id: context.rootGetters["user/userData"].id,
      name: context.rootGetters.userName,
    };
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to edit customer.",
    });
  },

  async addCustomer(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/newCustomer`;
    payload.user = {
      id: context.rootGetters["user/userData"].id,
      name: context.rootGetters.userName,
    };
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to add customer.",
    });
  },
  async addAddress(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/newAddress`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to add address.",
    });
  },
  async editAddress(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/editAddress`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to edit address.",
    });
  },
  async deleteAddress(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/delAddress`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to delete address.",
    });
  },

  async addInvoiceAddress(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/newFacturatieAddress`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to add invoice address.",
    });
  },
  async editInvoiceAddress(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/editFacturatieAddress`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to edit invoice address.",
    });
  },
  async deleteInvoiceAddress(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/delFacturatieAddress`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to delete invoice address.",
    });
  },

  async addTraveler(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/newTraveller`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to add traveler.",
    });
  },
  async editTraveler(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/editTraveller`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to edit traveler.",
    });
  },
  async deleteTraveler(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/delTraveller`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to delete traveler.",
    });
  },

  async addRoundtrip(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/newRoundtripAddress`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to add roundtrip address.",
    });
  },
  async editRoundtrip(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/editRoundtripAddress`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to edit roundtrip address.",
    });
  },
  async deleteRoundtrip(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/delRoundtripAddress`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to delete roundtrip address.",
    });
  },

  async addStaffel(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/addStaffelprijsKlant_id`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to add staffel price.",
    });
  },
  async editStaffel(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/editStaffelprijsKlant_id`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to edit staffel price.",
    });
  },
  async deleteStaffel(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/wisStaffelprijsKlant_id`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to delete staffel price.",
    });
  },

  async addPosttour(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/newPosttourPrijs`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to add posttour price.",
    });
  },
  async editPosttour(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/editPosttourPrijs`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to edit posttour price.",
    });
  },
  async deletePosttour(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/delPosttourPrijs`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to delete posttour price.",
    });
  },

  async addForfait(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/newForfaitPrijs`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to add forfait price.",
    });
  },
  async editForfait(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/editForfaitPrijs`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to edit forfait price.",
    });
  },
  async deleteForfait(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/delForfaitPrijs`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to delete forfait price.",
    });
  },

  async saveCustomerPriceCommon(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/savePriceAlgemeen`;
    payload.user = {
      id: context.rootGetters["user/userData"].id,
      name: context.rootGetters.userName,
    };
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to edit customer price (common).",
    });
  },

  async addressToTraveler(context, { id, tel, email }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/address2traveller/${id}?tel=${tel}&email=${email}`;
    await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to create traveler from location.",
    });
  },

  async getInvoiceIntern(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/facturatie/internalInvoiceData`;
    const response = await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to generate invoicing file.",
    });
    return response.data;
  },
  async getInvoiceExtern(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/facturatie/externalInvoiceData`;
    const response = await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to generate invoicing file.",
    });
    return response.data;
  },

  async getOnlinePayments(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/facturatie/checkOnlinePayments`;
    const response = await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to generate payments file.",
    });
    return response.data;
  },

  async transferHistory(context, {date}) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/facturatie/bijwerkenHistoriek?date=${date}`;
    const response = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to update history.",
    });
    return response.status;
  },

  async getInvoiceLisExtra(context, { from, until, locatie }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/facturatieloodswezen/extraritten?from=${from}&until=${until}&locatie=${locatie}`;
    const response = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to generate invoicing file.",
    });
    return response.data;
  },

  async addDraaiboek(context, { id, attachment }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/addDraaiboek`;

    const formData = new FormData();
    formData.append("id", id);
    formData.append("attachment", attachment);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const responseData = await response.json();
    if (!response.ok || !responseData.status) {
      const error = new Error(
        responseData.error_description || "Failed to add draaiboek."
      );
      throw error;
    }
  },

  async delDraaiboek(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/delDraaiboek`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to delete draaiboek.",
    });
  },

  async addKaart(context, { id, attachment }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/addKaart`;

    const formData = new FormData();
    formData.append("id", id);
    formData.append("attachment", attachment);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const responseData = await response.json();
    if (!response.ok || !responseData.status) {
      const error = new Error(
        responseData.error_description || "Failed to add kaart."
      );
      throw error;
    }
  },

  async delKaart(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/delKaart`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to delete kaart.",
    });
  },

  async addLogo(context, {id, attachment}){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/addLogo`;

    const formData = new FormData();
    formData.append("id", id);
    formData.append("attachment", attachment);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const responseData = await response.json();
    if (!response.ok || !responseData.status) {
      const error = new Error(
        responseData.error_description || "Failed to add logo."
      );
      throw error;
    }
    return responseData.data;
  },
  async delLogo(context, payload){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/delLogo`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to delete logo.",
    });
  }
};
