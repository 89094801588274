<template>
  <div class="modal tasks">
    <div class="modal__wrapper">
      <button class="close modal__close" @click="$emit('on-close')">
        <fa-icon icon="times"></fa-icon>
      </button>
      <div class="modal__title">{{activeTask?$t('tasks.completeTask'):$t('tasks.list')}}</div>
      <div class="modal__body">
        <div v-if="activeTask">
          <p><i>"{{activeTask.task}}"</i></p>
          <p>{{$t('tasks.completeText')}}</p>
          <div class="tasks__remark">
            <label>{{$t('data.remark')}}</label>
            <textarea class="textarea" v-model="activeTask.solverremarks"></textarea>
          </div>
        </div>
        <template v-else>
          <div class="tasks__input">
            <label>{{$t('tasks.task')}}</label>
            <textarea class="textarea" v-model="newData.task"></textarea>
            <label>{{$t('data.type')}}</label>
            <select class="select" v-model="newData.type">
              <option value="" disabled>-- Kies een type --</option>
              <option value="Billo">Billo</option>
              <option value="Pegase">Pegase</option>
              <option value="Vipdrive">Vipdrive</option>
              <option value="Loodswezen">Loodswezen</option>
              <option value="Planning">{{$t('tasks.planning')}}</option>
              <option value="Personeel">{{$t('tasks.pers')}}</option>
              <option value="Spoed">{{$t('tasks.urgent')}}</option>
              <option value="Andere">{{$t('tasks.other')}}</option>
            </select>
            <label>{{$t('tasks.priority')}}</label>
            <select class="select" v-model="newData.priority">
              <option>0</option>
              <option>1</option>
              <option>2</option>
            </select>
            <div>
              <button class="button button--accept" :class="{'button--disabled': !newData.type || !newData.task}"
                      @click="addTask">{{$t('tasks.add')}}
              </button>
            </div>
          </div>

          <div class="tasks__data" v-if="taskData && taskData.length">
            <table class="table">
              <thead>
              <tr class="table__header">
                <th>{{$t('data.created')}}</th>
                <th>{{$t('data.assignment')}}</th>
                <th>{{$t('data.type')}}</th>
                <th>{{$t('data.priority')}}</th>
                <th>{{$t('data.status')}}</th>
                <th>{{$t('data.assignedBy')}}</th>
                <th>{{$t('data.executor')}}</th>
                <th>{{$t('data.executed')}}</th>
                <th>{{$t('data.remark')}}</th>
                <th>{{$t('data.actions')}}</th>
              </tr>
              </thead>
              <tbody class="table__body">
              <tr class="table__body__item" :class="{'table__body__item--edited':item.priority==2 || item.type==='Spoed'}" v-for="(item,index) in openTasks"
                  :key="`task-item--${index}`">
                <td class="table__body__item__column">{{ item.creationdate }}</td>
                <td class="table__body__item__column">{{ item.task }}</td>
                <td class="table__body__item__column">{{ item.type }}</td>
                <td class="table__body__item__column">{{ item.priority }}</td>
                <td class="table__body__item__column">{{ item.status }}</td>
                <td class="table__body__item__column">{{ item.createdby }}</td>
                <td class="table__body__item__column">{{ item.solvedby }}</td>
                <td class="table__body__item__column">{{ item.solveddate }}</td>
                <td class="table__body__item__column">{{ item.solverremarks }}</td>
                <td>
                  <template v-if="item.status==='OPEN'">
                    <button class="info" :title="$t('tasks.startTask')" @click="acceptTask(item)">
                      <fa-icon icon="user-check"></fa-icon>
                    </button>
                    <button v-if="item.creator == currentUser" :title="$t('tasks.removeTask')" class="info delete" @click="deleteTask(item)">
                      <fa-icon icon="trash-alt"></fa-icon>
                    </button>
                  </template>
                  <template v-else-if="item.status==='IN PROGRESS' && item.solver == currentUser">
                    <button class="info" :title="$t('tasks.completeTask')" @click="activeTask=item">
                      <fa-icon icon="check"></fa-icon>
                    </button>
                    <button class="info delete" :title="$t('tasks.openTask')" @click="removeTask(item)">
                      <fa-icon icon="trash-alt"></fa-icon>
                    </button>
                  </template>
                  <template v-else-if="item.status==='IN PROGRESS'">
                    IN PROGRESS
                  </template>
                </td>
              </tr>
              <tr class="table__body__item" v-for="(item,index) in doneTasks"
                  :key="`dtask-item--${index}`">
                <td class="table__body__item__column">{{ item.creationdate }}</td>
                <td class="table__body__item__column">{{ item.task }}</td>
                <td class="table__body__item__column">{{ item.type }}</td>
                <td class="table__body__item__column">{{ item.priority }}</td>
                <td class="table__body__item__column">{{ item.status }}</td>
                <td class="table__body__item__column">{{ item.createdby }}</td>
                <td class="table__body__item__column">{{ item.solvedby }}</td>
                <td class="table__body__item__column">{{ item.solveddate }}</td>
                <td class="table__body__item__column">{{ item.solverremarks }}</td>
                <td>
                  /
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </template>
      </div>
      <div class="modal__controls" v-if="activeTask">
        <button class="button button--cancel" @click="activeTask=null">{{ $t('cancel') }}</button>
        <button class="button button--accept" @click="completeTask">{{ $t('confirm') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import {ObjectMixin} from "@/mixins/ObjectMixin";

export default {
  name: "TaskOverview",
  mixins: [ObjectMixin],
  data() {
    return {
      isLoading: false,
      activeTask: null,
      status: '',
      newData: {
        type: '',
        task: '',
        priority: 0
      }
    };
  },

  async created() {
    await this.getData();
  },

  watch: {
    'newData.type': {
      handler() {
        if (this.newData.type === 'Spoed') {
          this.newData.priority = 2;
        }
      }
    }
  },

  computed: {
    currentUser() {
      return this.$store.getters['user/userData']?.id;
    },

    taskData(){
      return this.$store.getters['dispatch/tasks'];
    },

    openTasks(){
      return this.taskData.filter(t => t.status !== 'DONE').sort((a,b) => b.priority  - a.priority);
    },

    doneTasks(){
      return this.taskData.filter(t => t.status === 'DONE').sort((a,b) => b.id  - a.id);
    }
  },

  methods: {
    async getData() {
      this.isLoading = true;
      try {
        await this.$store.dispatch('dispatch/getTasks', {status: this.status});
      } catch (e) {
        alert(e);
      }
      this.isLoading = false;
    },

    async acceptTask(item) {
      const payload = {
        id: item.id,
        type: item.type,
        task: item.task,
        status: 'IN PROGRESS',
        solver: this.currentUser,
        priority: item.priority,
        solverremarks: '',
        solvedDate: '',
      };
      try {
        await this.$store.dispatch('dispatch/editTask', payload);
        await this.getData();
      } catch (e) {
        alert(e);
      }
    },
    async completeTask() {
      const item = this.activeTask;
      const date = new Date();
      const payload = {
        id: item.id,
        type: item.type,
        task: item.task,
        status: 'DONE',
        solver: this.currentUser,
        priority: item.priority,
        solverremarks: item.solverremarks,
        solveddate: ObjectMixin.formatDateFullSqlString(date),
      };
      try {
        await this.$store.dispatch('dispatch/editTask', payload);
        this.activeTask=null;
        await this.getData();
      } catch (e) {
        alert(e);
      }
    },
    async removeTask(item) {
      const payload = {
        id: item.id,
        type: item.type,
        task: item.task,
        status: 'OPEN',
        solver: 0,
        priority: item.priority,
        solverremarks: '',
        solvedDate: '',
      };
      try {
        await this.$store.dispatch('dispatch/editTask', payload);
        await this.getData();
      } catch (e) {
        alert(e);
      }
    },
    async deleteTask(item) {
      try {
        await this.$store.dispatch('dispatch/delTask', {id: item.id});
        await this.getData();
      } catch (e) {
        alert(e);
      }
    },
    async addTask() {
      try {
        await this.$store.dispatch('dispatch/addTask', this.newData);
        this.newData = {
          type: '',
          task: '',
          priority: 0
        }
        await this.getData();
      } catch (e) {
        alert(e);
      }
    }
  }
}
</script>

<style scoped>

</style>