export default{
    airports(state){
        return state.airports;
    },
    carTypes(state){
        return state.carTypes;
    },
    hasAirports(state){
        return state.airports && state.airports.length;
    },
    delijnnrs(state){
        return state.delijnnrs;
    }
}