import mutations from "./mutations";
import actions from './actions';
import getters from './getters';

export default{
    namespaced: true,
    state(){
        return {
            customers: [],
            ritten: [],
            orders: [],
        }
    },
    mutations,
    actions,
    getters
}