import apihelper from "@/helpers/apihelper";

export default {
    async recoverPassword(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/hesusers/resetpasswordusername?username=${payload}`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to recover user.'
        });
        return responseData;
    },

    async checkPasswordResetValid(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/hesusers/passwordresetvalid?username=${payload.username}&code=${payload.code}`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'invalid request.'
        });
        return responseData;
    },

    async editPassword(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/hesusers/changepassword`;
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to change password for user.'
        });
        return responseData;
    },

    async getUserData(context) {
        const token = context.rootGetters.token;
        const username = context.rootGetters.userName;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/hesusers/detailuser?username=${username}`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'failed to fetch user data.'
        });
        if (!responseData.data || !responseData.data.actief) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch user data or access denied.'
            );
            throw error;
        }
        context.commit('setUserData', {
            userData: responseData.data
        });
        context.dispatch('logUserAction',{id:1,bonnr:1,message:"user data fetch HES-intranet"});
    },

    clearUserData(context) {
        context.commit('setUserData', {
            userData: null
        });
    },

    async logUserAction(context, payload) {
        const token = context.rootGetters.token;
        const data = payload;
        data.userid = context.getters.userData.id;
        data.username = context.rootGetters.userName;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/hesusers/loguseraction`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(data),
            errorMsg: 'Failed to log user action.'
        });
    },

    async saveDispatchSettings(context,payload){
        const token = context.rootGetters.token;
        payload.id = context.getters.userData.id;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/savedispatchsettings`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to save user dispatch settings.'
        });
        await context.dispatch('getUserData');
    },

    async getUsers(context){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/hesusers/listusers`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to fetch intranet users.'
        });
        context.commit('setUsers', {
            users: responseData.data
        });
    },
    async selectUser(context, {username}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/hesusers/detailuser?username=${username}`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to fetch user data.'
        });
        return responseData.data;
    },

    async addUser(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/hesusers/newuser`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to add user.'
        });
    },

    async editUser(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/hesusers/edituser`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to edit user.'
        });
    }
};