import apihelper from "@/helpers/apihelper";
export default {
    async getOrders(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/vipdrivehelpdesk/getBookings`;
        if(payload.sortField.toUpperCase() === "DATUM"){
            payload.sortOrder = payload.sortOrder === "DESC"?"ASC":"DESC";
        }
        const query = payload.query;
        const data = {
            PageNumber: payload.PageNumber,
            PageSize: payload.PageSize,
            RITID: query.idrit || "",
            PO: query.po || "",
            DATUM: query.datum || "",
            EMAIL: query.email || "",
            TEL: query.gsm || "",
            NAAM: query.naam || "",
            STRAAT: query.straat || "",
            POSTNR: query.postnr || "",
            STAD: query.stad || "",
            HT: query.ht || "",
            ORDERBY: payload.sortField || "",
            SORTORDER: payload.sortOrder
        };
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(data),
            errorMsg: 'Failed to fetch vip orders.'
        });
        context.commit('setOrders', responseData.data);
        context.commit('setPages', Math.ceil(responseData.metadata.count / payload.PageSize));
    },

    clearOrders(context){
        context.commit('setOrders', []);
        context.commit('setPages', 0);
    },

    async getOrderDetail(context, {bonnr}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/vipdrivehelpdesk/selectvipbooking?bonnr=${bonnr}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch vip order detail.'});
        return responseData;
    },

    async getOrderHistory(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/vipdrivehelpdesk/leeswijzigingen/${payload}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch vip order history.'});
        return responseData.data;
    },

    async getExtraPayments(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/vipdrivehelpdesk/leesextrabetalingen?bonnr=${payload}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch vip order extra payments.'});
        return responseData;
    },

    async getGoogleRoute(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/vipdrivehelpdesk/googleroutecorrected/${payload}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch vip order google route.'});
        return responseData;
    },

    async getRoutePickupTime(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/vipdrivehelpdesk/calcpickuptime/${payload}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch vip order pickup time.'});
        return responseData.data;
    },

    async saveOrder(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/vipdrivehelpdesk/SaveVipBooking`;
        payload.userid = context.rootGetters['user/userData'].id;
        payload.username = context.rootGetters.userName;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to save vip order.'
        });
    },

    async cancelOrder(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/vipdrivehelpdesk/cancelvipbooking`;
        payload.userid = context.rootGetters['user/userData'].id;
        payload.username = context.rootGetters.userName;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to cancel vip order.'
        });
    },

    async cancelVipBookingPO(context,payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/vipdrivehelpdesk/cancelvipbookingpo`;
        payload.userid = context.rootGetters['user/userData'].id;
        payload.username = context.rootGetters.userName;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to cancel vip order by po.'
        });
    },
    async cancelVipBookingID(context,payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/vipdrivehelpdesk/cancelvipbookingid`;
        payload.userid = context.rootGetters['user/userData'].id;
        payload.username = context.rootGetters.userName;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to cancel vip order by id.'
        });
    },

    async sendMail(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/sendmail/Sendemailextended`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to mail vip order.'
        });
    },

    async sendVipLink(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/vipdrivehelpdesk/sendlinkvipdrive?po=${payload}`;
        await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to email vip order po link.'});
    },

    async sendPegaseLink(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/vipdrivehelpdesk/sendlinkpegase?bonnr=${payload}`;
        await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to email pegase order po link.'});
    }
}