export default {
    centers(state){
        return state.centers;
    },
    hasCenters(state){
        return state.centers && state.centers.length;
    },

    agents(state){
        return state.agents;
    },
    hasAgents(state){
        return state.agents && state.agents.length;
    },

    pages(state){
        return state.pages;
    }
}