import apihelper from "@/helpers/apihelper";
export default {
  async getCustomerStaffels(context) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/pricecalculation/leesstaffelprijzen_klanten`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to load klant staffels.",
    });
    return responseData.data;
  },
  async getOAStaffels(context) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/pricecalculation/leesstaffelprijzen_oa`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to load Onderaannemer staffels.",
    });
    return responseData.data;
  },

  async submitCustomerStaffel(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/pricecalculation/${payload.id?'edit':'add'}StaffelPrijsKlant`;
    payload.user = {
      id: context.rootGetters["user/userData"].id,
      name: context.rootGetters.userName,
    };
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to post customer staffel.",
    });
  },
  async submitOAStaffel(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/pricecalculation/${payload.id?'edit':'add'}StaffelPrijs_oa`;
    payload.user = {
      id: context.rootGetters["user/userData"].id,
      name: context.rootGetters.userName,
    };
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to post onderaannemer staffel.",
    });
  },
};
