import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export default {
  namespaced: true,
  state() {
    return {
      ritten: [],
      numbers: null,
      pages: 0,
      wagens: [
        "E",
        "V",
        "SEDAN",
        "BMW5",
        "BMW7",
        "SKLASSE",
        "EQS",
        "TAXI",
        "LIMO",
        "BUS6",
        "BUS7",
        "BUS8",
        "BUS16",
        "BUS20",
        "BUS22",
        "BUS30",
        "BUS52",
        "WAGENKLANT",
      ],
      bedrijven: [],
      customers: [],

      activeRit: null,
      activeSubReizigers: [],
      alarm: null,
      lastChauffeur: null,
      chauffeurs: [],
      activeChauffeur: null,
      tasks: [],
      LIJN: [
        52335, 52219, 52220, 52221, 52222, 52223, 52328, 52236, 52213, 52229,
        52228, 52227, 52226, 52235, 52231, 52233, 52234, 52237, 52230, 52242,
        52243, 52334, 52218, 52217, 52336, 52337, 52317, 52318, 52319, 52320,
        52321, 52239, 52278, 52238, 52330, 52329, 52327, 52331, 52265, 52375,
        52241,
      ],

      //activeCustomer: null,
      //activeReizigers: [],
      //activeAddresses: [],
      //activeInvoiceAddresses: []
    };
  },
  mutations,
  actions,
  getters,
};
