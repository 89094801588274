<template>
  <div class="modal">
    <div class="modal__wrapper">
      <button class="close modal__close" @click="$emit('on-close')">
        <fa-icon icon="times"></fa-icon>
      </button>
      <div class="modal__title">{{ $t('smsStatus.title') }}</div>
      <div class="modal__body">
        <p>{{ $t('smsStatus.last') }} <b>{{ alarm.sms }}</b></p>
        <p>{{ $t('smsStatus.lastTime') }} <b>{{ alarm.lastsms }}</b></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlarmSms",
  computed: {
    alarm() {
      return this.$store.getters['dispatch/alarm'];
    }
  }
}
</script>