export default{
    setVouchers(state, payload){
        state.vouchers = payload;
    },

    setPages(state, payload){
        state.pages = payload;
    },

    setPromos(state, payload){
        state.promos = payload;
    }
}